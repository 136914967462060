// global-overlay-plugin.js
import GlobalOverlay from '../components/GlobalOverlay.vue';

export default {
  install(Vue) {
    const GlobalOverlayConstructor = Vue.extend(GlobalOverlay);
    const instance = new GlobalOverlayConstructor();
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);

    Vue.prototype.$globalOverlay = {
      show(text) {
        instance.show(text);
      },
      hide() {
        instance.hide();
      }
    };
  }
};