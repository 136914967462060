export default {
    bind(el, binding) {
      const updateText = (value) => {
        if (!value) return;
        
        let maskedText;
        if (value.length > 5) {
          maskedText = value.slice(0, 2) + '***' + value.slice(-2);
        } else {
          maskedText = value.slice(0, 2) + '*'.repeat(value.length - 2);
        }
        
        el.textContent = maskedText;
      };
  
      updateText(binding.value);
  
      el._updateMaskedText = updateText;
    },
    
    update(el, binding) {
      el._updateMaskedText(binding.value);
    }
  };