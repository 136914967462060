<template>
    <div class="content" id="app">
        <router-view></router-view>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class App extends Vue {
    created() {
        console.log('ip:',window.location.hostname)
       
        // this.getLang()
        // 获取用户的所有语言偏好
        const userLanguages = navigator.languages;
        if(userLanguages[0]=='zh-CN'){
            this.$i18n.locale  = 'zh'
        }else{
            this.$i18n.locale  = 'ru'
        }
    }
    getLang() {
        // 获取用户的所有语言偏好
        const userLanguages = navigator.languages;
        // console.log("用户的语言偏好列表:", userLanguages[4]);
        this.$i18n.locale = userLanguages[4]; // 修改页面需要显示的语言
    }
}
</script>

<style>

html,body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}
</style>
