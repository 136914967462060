const zh = {
    dulicang:{
        "img1":require('@/assets/dulicang/zh_1.png'),
        "img2":require('@/assets/dulicang/zh_2.png'),
    },
    liuyan:{
        "请输入姓名": "请输入姓名",
        "请输入邮箱": "请输入邮箱",
        "请输入内容": "请输入内容",
        "留言成功":"留言成功",
    },
    index:{
        "img1":require('@/assets/index/zh_1.png'),
        "img2":require('@/assets/index/zh_2.png'),
        "img3":require('@/assets/index/zh_3.png'),
        "img4":require('@/assets/index/zh_4.png'),
        "img5":require('@/assets/index/zh_5.png'),
        "img6":require('@/assets/index/zh_6.png'),
        "img7":require('@/assets/index/zh_7.png'),
        "img8":require('@/assets/index/zh_8.png'),
        "img9":require('@/assets/index/zh_9.png'),
        "img10":require('@/assets/index/zh_10.png'),
        "img11":require('@/assets/index/zh_11.png'),
        "img12":require('@/assets/index/zh_12.png'),
        "img13":require('@/assets/index/zh_13.png'),
        "img14":require('@/assets/index/zh_14.png'),
        "img15":require('@/assets/index/zh_15.png'),
        "img16":require('@/assets/index/zh_16.png'),
        "img17":require('@/assets/index/zh_17.png'),
        "img18":require('@/assets/index/zh_18.png'),
        "img19":require('@/assets/index/zh_19.png'),
        "img20":require('@/assets/index/zh_20.png'),
        "img21":require('@/assets/index/zh_21.png'),
        "img22":require('@/assets/index/zh_22.png'),
        "img23":require('@/assets/index/zh_23.png'),
        "img24":require('@/assets/index/zh_24.png'),
        "img25":require('@/assets/index/zh_25.png'),
        "img26":require('@/assets/index/zh_26.png'),
        "img27":require('@/assets/index/zh_27.png'),
        "img28":require('@/assets/index/zh_28.png'),
        "img29":require('@/assets/index/zh_29.png'),
        "img30":require('@/assets/index/zh_30.png'),
        "img31":require('@/assets/index/zh_31.png'),
        "img32":require('@/assets/index/zh_32.png'),
        "img33":require('@/assets/index/zh_33.png'),
        "img34":require('@/assets/index/zh_34.png'),
        "img35":require('@/assets/index/zh_35.png'),
        "img36":require('@/assets/index/zh_36.png'),
        "img37":require('@/assets/index/zh_37.png'),
        "img38":require('@/assets/index/zh_38.png'),
        "img39":require('@/assets/index/zh_39.png'),
        "img40":require('@/assets/index/zh_40.png'),
        "img41":require('@/assets/index/zh_41.png'),
        "img42":require('@/assets/index/zh_42.png'),
        "img43":require('@/assets/index/zh_43.png'),
        "img44":require('@/assets/index/zh_44.png'),
        "img45":require('@/assets/index/zh_45.png'),
    },
    yunshu1:{
        "img1":require('@/assets/yunshu/zh_1.png'),
        "img2":require('@/assets/yunshu/zh_2.png'),
        "img3":require('@/assets/yunshu/zh_3.png'),
        "img4":require('@/assets/yunshu/zh_4.png'),
        "img5":require('@/assets/yunshu/zh_5.png'),
        "img6":require('@/assets/yunshu/zh_6.png'),
        "title1":"我们运输的",
        "title2":"核心理念",
        "title3":"定制物流、实时跟踪、专家支持，全面提升运输安全与效率。",
        "title4":"货车运输",
        "title5":"轮船运输",
        "title6":"飞机运输",
        "title7":"火车运输",
        "name1":"✔ 高效物流，货物安全抵达",
        "name2":"定制物流方案，确保货物安全、准时送达。",
        "name3":"✔ 实时跟踪、掌握运输动态",
        "name4":"实时跟踪运输进度，提升运输感知。",
        "name5":"✔ 专业团队，助力运输优化",
        "name6":"专家团队优化运输流程，提升市场竞争力。",
        "name7":"我们的优势",
        "name8":"加入我们",
        "name9":"运输订单",
        "name10":"公司愿景",
        "name11":"独立仓会员开放",


    },
    caigou: {
        "img1": require('@/assets/caigou/zh_1.png'),
        "img2": require('@/assets/caigou/zh_2.png'),
        "img3": require('@/assets/caigou/zh_3.png'),
        "img4": require('@/assets/caigou/zh_4.png'),
        "img5": require('@/assets/caigou/zh_5.png'),
        "img6": require('@/assets/caigou/zh_6.png'),
        "img7": require('@/assets/caigou/zh_7.png'),
        "img8": require('@/assets/caigou/zh_8.png'),
        "title1": "货物出售订单",
        "title2": "采购订单",
    },
    daohang: {
        "title1": "首页",
        "title2": "业务",
        "title3": "案例",
        "title4": "关于我们",
        "title5": "登录",
        "title6": "让外贸像内贸一样简单",
        "name1": "跨境货代",
        "name2": "运输",
        "name3": "供应商",
        "name4": "服务商",
        "name5": "采购商",
        "name6": "独立仓",
        "name7": "退出",
        "img1": require('@/assets/dulicang_zh.png'),
    },
    guanyu: {
        "name1": "跨境贸易全覆盖",
        "name2": "订单拍卖更公平",
        "name3": "数据信息全互通",
        "name4": "售后赔付有保证",
        "title1": "中国综保区明星企业",
        "title2": "辽宁省产业联盟官方指定合作网站",
        "title3": "内蒙古商务厅重点发展企业",
        "title4": "哈尔滨跨境综合产业区明星企业",
        "title5": "中国跨境平台试点企业",
        "title6": "四平市政府制定出口合作企业",
        "title7": "让外贸像内贸一样简单",
        "img1": require('@/assets/guanyu/zh_1.jpg'),
        "img2": require('@/assets/guanyu/zh_2.png'),
        "img3": require('@/assets/guanyu/zh_3.png'),
        "img4": require('@/assets/guanyu/zh_4.png'),
        "img5": require('@/assets/guanyu/zh_5.png'),
        "img6": require('@/assets/guanyu/zh_6.png'),
        "img7": require('@/assets/guanyu/zh_7.png'),
        "img8": require('@/assets/guanyu/zh_8.png'),
        "img9": require('@/assets/guanyu/zh_9.png'),
        "img91": require('@/assets/guanyu/zh_19_1.png'),
        "img92": require('@/assets/guanyu/zh_19_2.png'),
        "img93": require('@/assets/guanyu/zh_19_3.png'),
        "img10": require('@/assets/guanyu/zh_10.png'),
        "img11": require('@/assets/guanyu/zh_11.png'),
        "img12": require('@/assets/guanyu/zh_12.png'),
        "img13": require('@/assets/guanyu/zh_13.png'),
        "img14": require('@/assets/guanyu/zh_14.png'),
        "img15": require('@/assets/guanyu/zh_15.png'),
        "img16": require('@/assets/guanyu/zh_16.png'),
        "img17": require('@/assets/guanyu/zh_17.png'),
        "img18": require('@/assets/guanyu/zh_18.png'),
        "img19": require('@/assets/guanyu/zh_19.png'),
        "img20": require('@/assets/guanyu/zh_20.png'),
        "img21": require('@/assets/guanyu/zh_21.png'),
        "img22": require('@/assets/guanyu/zh_22.png'),
        "img23": require('@/assets/guanyu/zh_23.png'),
        "img24": require('@/assets/guanyu/zh_24.png'),
        "img25": require('@/assets/guanyu/zh_25.png'),
        "img26": require('@/assets/guanyu/zh_26.png'),
        "img27": require('@/assets/guanyu/zh_27.png'),
        "img28": require('@/assets/guanyu/zh_28.png'),
        "img29": require('@/assets/guanyu/zh_29.png'),
        "img30": require('@/assets/guanyu/zh_30.png'),
        "img31": require('@/assets/guanyu/zh_31.png'),
        "img32": require('@/assets/guanyu/zh_32.png'),
        "img34": require('@/assets/guanyu/zh_34.png'),
        "img35": require('@/assets/guanyu/zh_35.png'),
        "img36": require('@/assets/guanyu/zh_36.png'),
        "img37": require('@/assets/guanyu/zh_37.png'),
        "img38": require('@/assets/guanyu/zh_38.png'),
        "img39": require('@/assets/guanyu/zh_39.png'),
        "img40": require('@/assets/guanyu/zh_40.png'),
        "img41": require('@/assets/guanyu/zh_41.png'),
        "img42": require('@/assets/guanyu/zh_42.png'),
        "img43": require('@/assets/guanyu/zh_43.png'),
        "img44": require('@/assets/guanyu/zh_44.png'),
        "img45": require('@/assets/guanyu/zh_45.png'),
        "img46": require('@/assets/guanyu/zh_46.png'),
        "img47": require('@/assets/guanyu/zh_47.png'),
        "img48": require('@/assets/guanyu/zh_48.png'),
        "img49": require('@/assets/guanyu/zh_49.png'),
        "img50": require('@/assets/guanyu/zh_50.png'),
        "img51": require('@/assets/guanyu/zh_51.png'),
        "imgios": require('@/assets/guanyu/zh_ios.png'),
        "imgand": require('@/assets/guanyu/zh_and.png'),
        "imgxa": require('@/assets/guanyu/zh_xz.png'),
        "img52": require('@/assets/guanyu/hero_bg01.jpg'),
        "img53": require('@/assets/guanyu/hero_bg02.jpg'),
        "img54": require('@/assets/guanyu/hero_bg03.jpg'),
        "img55": require('@/assets/guanyu/hero_bg04.jpg'),
        "img56": require('@/assets/guanyu/hero_bg05.jpg'),
        "img57": require('@/assets/guanyu/hero_bg06.jpg'),
    },
    anli: {
        "name": "案例介绍",
        "detail": "优秀的案例内容",
        "img": require('@/assets/anli/zh.png'),
        "img1": require('@/assets/anli/zh_1.png'),
        "img2": require('@/assets/anli/zh_2.png'),
        "img3": require('@/assets/anli/zh_3.png'),
        "img4": require('@/assets/anli/zh_4.png'),
        "img5": require('@/assets/anli/zh_5.png'),
        "img6": require('@/assets/anli/zh_6.png'),
        "img7": require('@/assets/anli/zh_7.png'),
        "img8": require('@/assets/anli/zh_8.png'),
        "img9": require('@/assets/anli/zh_9.png'),
        "img10": require('@/assets/anli/zh_10.png'),
        "img11": require('@/assets/anli/zh_11.png'),
    },
    "name": "您的姓名",
    "mobile": "您的邮箱/电话",
    "content": "详细内容",
    "submit": "确认提交",
    "qname": "请输入您的姓名",
    "qmobile": "请输入你的手机号",
    "qcontent": "请输入内容",
    "download": "点击下载",
    yunshu: {
        "运输路线": "运输路线",
        "运输方式": "运输方式",
        "货物类型": "货物类型",
        "请选择": "请选择",
        "搜索": "搜索 ",
        "中国": "中国",
        "俄罗斯": "俄罗斯",
        "国籍": "国籍",
        "国籍 + 图标": "国籍 + 图标",
        "运输公司": "运输公司",
        "运输路线": "运输路线",
        "出发城市": "出发城市",
        "目的城市": "目的城市",
        "运输方式": "运输方式",
    },

    login: {
        "请先登录": "请先登录",
        title: '我是标题',
        "text": `专业的团队`,
        "text2": "造就专业的气氛",
        "text1": " GLUZ为您提供不一样的跨境贸易",
        "登录":"登录",
        "账号":"账号",
        "密码":"密码",
        "请输入手机号":"请输入手机号",
        "请输入密码":"请输入密码",
        "切换语言":"切换语言",
    },
    huodai: {
        "数据统计": "数据统计",
        "查询": "查询",
        "运输方式": "运输方式",
        "货物类型": "货物类型",
        "通关服务": "通关服务",
        "搜索": "搜索",
        "报关": "报关",
        "清关": "清关",
        "报关+清关": "报关+清关",
        "列表": "列表",
        "请选择": "请选择",
        "中国": "中国",
        "俄罗斯": "俄罗斯",
        "国籍": "国籍",
        "运输公司": "运输公司",
        "运输路线": "运输路线",
        "通关口岸": "通关口岸",
        "操作": "操作",
        "聊聊": "聊聊",
        "看看": "看看",
        "出发城市": "出发城市",
        "汽运": "汽运",
        "空运": "空运",
        "海运": "海运",
        "火车": "火车",
        "不限": "不限",
        "联系客服": "联系客服",
        "咨询客服可以解决你一切问题": "咨询客服可以解决你一切问题",
        "咨询客服": "咨询客服",
        "目的城市": "目的城市",
        "所在国家": "所在国家",
        "所属国籍": "所属国籍",
        "发布人": "发布人",
        "任务名称": "任务名称",
        "任务要求": "任务要求",
        "任务时长": "任务时长",
        "工作内容": "工作内容",
        "商品名称": "商品名称",
        "商品类型": "商品类型",
        "商品分类": "商品分类",
        "请输入": "请输入",
        "采购商名称": "采购商名称",
        "地址": "地址",
        "采购数量": "采购数量",
        "商品介绍": "商品介绍",
        "商品图片": "商品图片",
        "中国货物": "中国货物",
        "俄罗斯货物": "俄罗斯货物",
        "在俄中国货物": "在俄中国货物",
        "在中俄罗斯货物": "在中俄罗斯货物",
        "我们的优势": "我们的优势",
        "服务城市数量": "服务城市数量",
        "专业代办人员数量": "专业代办人员数量",
        "服务好评": "服务好评",
        "发布任务列表": "发布任务列表",
        "覆盖城市数量": "覆盖城市数量",
        "覆盖口岸数量": "覆盖口岸数量",
        "服务商数量": "服务商数量",
        "跨境货代订单": "跨境货代订单",
        "公司愿景": "公司愿景",
        "我们的优势": "我们的优势",
        "提升品牌影响力，拓展俄罗斯市场": "提升品牌影响力，拓展俄罗斯市场",
        "高效管理交易流程": "高效管理交易流程",
        "提升市场竞争力": "提升市场竞争力",
        "采购订单": "采购订单",
        "国籍 采购商名称": "国籍 采购商名称",
        "欢迎使用GLUZ平台":"欢迎使用GLUZ平台",
        "为您提供优质的跨境服务":"为您提供优质的跨境服务",
        "平台能力":"平台能力",
        "品牌拓展、智能管理与专业支持，助力企业高效进入俄罗斯市场并提升竞争力":"品牌拓展、智能管理与专业支持，助力企业高效进入俄罗斯市场并提升竞争力",
        "营业执照":"营业执照",
        "蒙ICP备2023002271号":"蒙ICP备2023002271号",
        "内蒙古格路兹电子商务有限公司 版权所有":"内蒙古格路兹电子商务有限公司 版权所有",
        "发布时间":"发布时间",
    },
    "item": {
        "goods_brief": "goods_brief",
        "mode": "mode",
        "car_category_id_text": "car_category_id_text",
        "msg": "msg",
        "profile": "profile",
        "title": "title",
        "load_num": "load_num",
        "name": "name",
        "brand": "brand",
        "goods_num": "goods_num",
        "goods_weight": "goods_weight",
        "product_weight": "product_weight",
        "goods_volume": "goods_volume",
        "product_volume": "product_volume",
        "show_text": "show_text",
        "mode_value": "value",
        "person_name": "person_name",
        "label": "label",
        "car_l": "car_l",
        "car_w": "car_w",
        "car_h": "car_h",
        "load_num": "load_num",
        "company_name": "company_name",
        "industry": "industry",
        "category_name": "category_name",
        "skill": "skill",
        "content": "content",
        "desc": "desc",
        "introduction": "introduction",
        "con": "con",
        "con_text": "con_text",
        "speed_ranking": "speed_ranking",
        "price_ranking": "price_ranking",
        "num_ranking": "num_ranking",
        "show_is_topping": "show_is_topping",
        "main_product": "main_product",
        "goods_name": "goods_name",
        "product": "product",
        "username": "username",
        "transport_name": "transport_name",
        "rating": "rating",
        "mobile": "mobile",
        "country_name": "country_name",
        "email": "email",
        "idnum": "idnum",
        "passport_code": "passport_code",
        "passport_image": "passport_image",
        "product_image": "product_image",
        "business_image": "business_image",
        "license_image": "license_image",
        "shop_image": "shop_image",
        "show_goods_num": "show_goods_num",
        "number_unit": "number_unit",
        "price_unit": "price_unit",
        "whole_diffuse": "whole_diffuse",
        "text": "text",
        "car_category_name": "car_category_name",
        "text": "text",
        "product_address": "product_address",
        "product_address_details": "product_address_details",
        "reach_address_details": "reach_address_details",
        "scope_category": "scope_category",
        "user": {
            "username": "username",
            "identity_name": "identity_name"
        },
        "city": {
            "mergename": "mergename",
            "name": "name",
            "shortname": "shortname"
        },
        "reach_address": {
            "mergename": "mergename",
            "name": "name",
            "shortname": "shortname"
        },
        "product_address": {
            "mergename": "mergename",
            "name": "name",
            "shortname": "shortname"
        }
    },
}
export default zh;